<!-- <input
  #searchBox
  [(ngModel)]="term"
  id="search-box"
  (input)="search(searchBox.value)"
  (keyup.enter)="onSubmit()"
/> -->
<ng-container
  *ngIf="
    !(articlesList && articlesList.articles && articlesList.articles.length > 0)
  "
>
  <form
    [formGroup]="formSearch"
    (ngSubmit)="onSubmit()"
    style="height: calc(100vh - 64px)"
  >
    <div class="wrap">
      <div
        [routerLink]="['/']"
        style="text-align: center; margin: 32px 0; cursor: pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 671 181.693"
        >
          <g
            id="Group_30"
            data-name="Group 30"
            transform="translate(-347.344 -293.065)"
          >
            <g id="Group_1" data-name="Group 1">
              <rect
                id="Rectangle_1"
                data-name="Rectangle 1"
                width="12.676"
                height="50.661"
                transform="translate(431.862 424.097)"
                fill="#a096dd"
              />
            </g>
            <g id="Group_2" data-name="Group 2">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M431.862,343.7l6.343.023,6.321-.046V293.065H431.862Z"
                fill="#a096dd"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_3" data-name="Group 3">
              <rect
                id="Rectangle_2"
                data-name="Rectangle 2"
                width="50.728"
                height="12.676"
                transform="translate(347.344 377.555)"
                fill="#a096dd"
              />
            </g>
            <g id="Group_4" data-name="Group 4">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M378.462,315.162l-8.972,8.971,35.875,35.875,8.972-8.971Z"
                fill="#a096dd"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_5" data-name="Group 5">
              <path
                id="Path_3"
                data-name="Path 3"
                d="M471.031,407.776l-8.972,8.972,35.875,35.875,8.972-8.972Z"
                fill="#a096dd"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_6" data-name="Group 6">
              <path
                id="Path_4"
                data-name="Path 4"
                d="M414.32,416.731l-8.971-8.972-35.875,35.875,8.971,8.972Z"
                fill="#a096dd"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_7" data-name="Group 7">
              <path
                id="Path_5"
                data-name="Path 5"
                d="M506.915,324.143l-8.971-8.972-35.875,35.875,8.971,8.972Z"
                fill="#a096dd"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_8" data-name="Group 8">
              <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="50.66"
                height="12.676"
                transform="translate(478.374 377.555)"
                fill="#a096dd"
              />
            </g>
            <g id="Group_9" data-name="Group 9">
              <path
                id="Path_6"
                data-name="Path 6"
                d="M455.59,442.37l11.555,27.853,11.7-4.853L467.3,437.538Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_10" data-name="Group 10">
              <path
                id="Path_7"
                data-name="Path 7"
                d="M397.522,302.4l11.556,27.853,11.7-4.832-11.555-27.853Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_11" data-name="Group 11">
              <path
                id="Path_8"
                data-name="Path 8"
                d="M409.142,437.564,397.618,465.43l11.7,4.84,11.515-27.845Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_12" data-name="Group 12">
              <path
                id="Path_9"
                data-name="Path 9"
                d="M467.053,297.533,455.529,325.4l11.694,4.86,11.524-27.866Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_13" data-name="Group 13">
              <path
                id="Path_10"
                data-name="Path 10"
                d="M379.713,401.292,351.86,412.847l4.853,11.7L384.545,413Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_11"
                data-name="Path 11"
                d="M519.679,343.224,491.826,354.78l4.832,11.7,27.853-11.555Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_15" data-name="Group 15">
              <path
                id="Path_12"
                data-name="Path 12"
                d="M384.533,354.842l-27.866-11.524-4.839,11.7,27.845,11.515Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_16" data-name="Group 16">
              <path
                id="Path_13"
                data-name="Path 13"
                d="M524.564,412.753,496.7,401.229l-4.86,11.694,27.86,11.524Z"
                fill="#631a86"
                fill-rule="evenodd"
              />
            </g>
            <g id="Group_17" data-name="Group 17">
              <path
                id="Path_14"
                data-name="Path 14"
                d="M616.974,370.364c12.179,0,20.878-4.712,25.591-13.992l-5.655-1.812c-3.7,7.467-10.512,11.019-19.936,11.019-13.992,0-21.821-7.9-21.821-21.386,0-13.411,7.829-21.023,22.256-21.023,9.787,0,16.094,3.407,19.5,10.8l5.582-1.812c-4.35-9.207-12.614-13.774-25.083-13.774-17.834,0-28.056,9.642-28.056,25.881C589.353,360.577,599.5,370.364,616.974,370.364Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_18" data-name="Group 18">
              <path
                id="Path_15"
                data-name="Path 15"
                d="M680.478,370.364c17.254,0,27.911-10.222,27.911-25.953,0-15.8-10.657-26.026-27.911-26.026-17.109,0-27.838,10.222-27.838,26.026C652.64,360.142,663.369,370.364,680.478,370.364Zm0-4.93c-13.7,0-21.893-8.119-21.893-21.023s8.119-21.1,21.893-21.1,21.966,8.191,21.966,21.1c0,12.831-8.192,21.023-21.966,21.023Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_19" data-name="Group 19">
              <path
                id="Path_16"
                data-name="Path 16"
                d="M738.548,363.767,720.5,319.11h-6.524l21.313,50.529h6.525l21.241-50.529H756.53Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_20" data-name="Group 20">
              <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="6.017"
                height="50.529"
                transform="translate(773.852 319.11)"
                fill="#21222d"
              />
            </g>
            <g id="Group_21" data-name="Group 21">
              <path
                id="Path_17"
                data-name="Path 17"
                d="M818.217,369.639c16.964,0,26.316-8.845,26.316-24.866,0-16.311-9.569-25.663-26.171-25.663H794.946v50.529Zm-17.253-45.6h16.673c12.18,0,20.879,6.308,20.879,20.589,0,15.151-10.439,20.081-21.386,20.081H800.964Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_22" data-name="Group 22">
              <path
                id="Path_18"
                data-name="Path 18"
                d="M628.356,448.175v-10.8h-25.3V397.646H589.933v50.529Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_23" data-name="Group 23">
              <path
                id="Path_19"
                data-name="Path 19"
                d="M679.027,448.175V438.6H650.391V427.586H677.07v-9.714H650.391V407.215h28.636v-9.569H637.27v50.529Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_24" data-name="Group 24">
              <path
                id="Path_20"
                data-name="Path 20"
                d="M743.766,448.175l-21.749-50.529H708.171l-21.748,50.529H700.7l3.552-9.135h21.6l3.479,9.135ZM715.13,411.057l7.032,18.342H708.026Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_25" data-name="Group 25">
              <path
                id="Path_21"
                data-name="Path 21"
                d="M789.437,428.674c6.235-1.813,10.729-6.017,10.729-15.152,0-13.121-9.5-15.876-21.241-15.876H752.03v50.529h13.049V429.906h11.164l10.584,18.269h15.007Zm-11.019-21.459c3.7,0,8.554.435,8.554,6.452,0,6.09-4.784,6.6-8.554,6.6H765.079V407.215Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_26" data-name="Group 26">
              <path
                id="Path_22"
                data-name="Path 22"
                d="M848.592,428.819l-25.155-31.173H811.33v50.529h13.122V416.857l25.155,31.318h12.18V397.646h-13.2Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_27" data-name="Group 27">
              <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="13.267"
                height="50.529"
                transform="translate(873.821 397.646)"
                fill="#21222d"
              />
            </g>
            <g id="Group_28" data-name="Group 28">
              <path
                id="Path_23"
                data-name="Path 23"
                d="M936.458,428.819,911.3,397.646H899.2v50.529h13.122V416.857l25.156,31.318h12.179V397.646h-13.2Z"
                fill="#21222d"
              />
            </g>
            <g id="Group_29" data-name="Group 29">
              <path
                id="Path_24"
                data-name="Path 24"
                d="M1004.96,429.761c-.29,5-3.044,9.714-14.136,9.714-12.324,0-18.124-6.524-18.124-16.456,0-10.222,5.437-16.674,17.544-16.674,8.409,0,12.9,2.9,15.659,8.917l12.4-3.842c-4.784-9.932-15.006-15.006-27.91-15.006-18.776,0-30.81,10.511-30.81,26.968,0,15.078,11.091,26.025,30.3,26.025,7.685,0,14.137-1.667,18.124-5.944l.653,4.712h9.641V420.409H992.494v9.352Z"
                fill="#21222d"
              />
            </g>
          </g>
          <g
            id="Group_2-2"
            data-name="Group 2"
            transform="translate(-29.867 -282.522)"
          >
            <g id="Rectangle_1-2" data-name="Rectangle 1">
              <rect
                id="Rectangle_1-3"
                data-name="Rectangle 1"
                width="49"
                height="23"
                rx="4"
                transform="translate(651.867 350.522)"
                fill="#a096dd"
              />
            </g>
            <g id="BETA" style="isolation: isolate">
              <g id="Group_1-2" data-name="Group 1" style="isolation: isolate">
                <path
                  id="Path_1-2"
                  data-name="Path 1"
                  d="M661.749,366.521v-8.934h2.884a4.007,4.007,0,0,1,2.287.533,1.839,1.839,0,0,1,.762,1.613,2.741,2.741,0,0,1-.078.654,2.085,2.085,0,0,1-.243.584,1.935,1.935,0,0,1-.41.471,1.827,1.827,0,0,1-.581.322,2.069,2.069,0,0,1,.639.215,1.889,1.889,0,0,1,.9,1.022,2.162,2.162,0,0,1,.134.779,2.4,2.4,0,0,1-.916,2.054,4.349,4.349,0,0,1-2.646.687Zm4.183-6.494a1.216,1.216,0,0,0-.082-.458.834.834,0,0,0-.263-.348,1.308,1.308,0,0,0-.465-.223,2.622,2.622,0,0,0-.687-.078h-1.018v2.365h1.018a2.123,2.123,0,0,0,.694-.1,1.25,1.25,0,0,0,.465-.274,1.019,1.019,0,0,0,.26-.4A1.482,1.482,0,0,0,665.932,360.027Zm-1.34,5.12a2.23,2.23,0,0,0,.708-.1,1.48,1.48,0,0,0,.509-.283,1.2,1.2,0,0,0,.311-.428,1.312,1.312,0,0,0,.106-.526,1.075,1.075,0,0,0-.434-.91,2.207,2.207,0,0,0-1.33-.328h-1.045v2.577Z"
                  fill="#631a86"
                />
                <path
                  id="Path_2-2"
                  data-name="Path 2"
                  d="M669.822,366.521v-8.934h5.38v1.422h-3.671v2.221h3.5v1.388h-3.5V365.1H675.2v1.421Z"
                  fill="#631a86"
                />
                <path
                  id="Path_3-2"
                  data-name="Path 3"
                  d="M680.971,358.988v7.533h-1.722v-7.533H676.8v-1.4h6.617v1.4Z"
                  fill="#631a86"
                />
                <path
                  id="Path_4-2"
                  data-name="Path 4"
                  d="M689.77,366.521l-.493-1.763H686.16l-.506,1.763h-1.668l2.646-8.934h2.351l2.646,8.934Zm-2.031-7.293-1.176,4.156H688.9Z"
                  fill="#631a86"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="search">
        <input
          type="search"
          class="searchTerm"
          name="term"
          id="term"
          formControlName="term"
          maxlength="512"
          autocomplete="off"
          title="Search"
          aria-label="Search"
          dir="ltr"
          spellcheck="false"
          autofocus="autofocus"
        />
        <button type="submit" value="Search" class="searchButton">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <p
      *ngIf="
        articlesList &&
        articlesList.articles &&
        articlesList.articles.length === 0 &&
        formSearch.value.term
      "
    >
      A sua pesquisa não retornou resultados.
    </p>
  </form>
</ng-container>
<p style="position: absolute; top: 12px; left: 16px" *ngIf="searching">
  Searching...
</p>
<ng-container
  *ngIf="
    articlesList && articlesList.articles && articlesList.articles.length > 0
  "
>
  <nav>
    <form
      [formGroup]="formSearch"
      (ngSubmit)="onSubmit()"
      style="margin: 0 32px"
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
        "
      >
        <div
          [routerLink]="['/']"
          style="text-align: center; margin: 32px 32px 32px 0; cursor: pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="covid-logo-search-results"
            viewBox="0 0 671 181.693"
          >
            <g
              id="Group_30"
              data-name="Group 30"
              transform="translate(-347.344 -293.065)"
            >
              <g id="Group_1" data-name="Group 1">
                <rect
                  id="Rectangle_1"
                  data-name="Rectangle 1"
                  width="12.676"
                  height="50.661"
                  transform="translate(431.862 424.097)"
                  fill="#a096dd"
                />
              </g>
              <g id="Group_2" data-name="Group 2">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M431.862,343.7l6.343.023,6.321-.046V293.065H431.862Z"
                  fill="#a096dd"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_3" data-name="Group 3">
                <rect
                  id="Rectangle_2"
                  data-name="Rectangle 2"
                  width="50.728"
                  height="12.676"
                  transform="translate(347.344 377.555)"
                  fill="#a096dd"
                />
              </g>
              <g id="Group_4" data-name="Group 4">
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M378.462,315.162l-8.972,8.971,35.875,35.875,8.972-8.971Z"
                  fill="#a096dd"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_5" data-name="Group 5">
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M471.031,407.776l-8.972,8.972,35.875,35.875,8.972-8.972Z"
                  fill="#a096dd"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_6" data-name="Group 6">
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M414.32,416.731l-8.971-8.972-35.875,35.875,8.971,8.972Z"
                  fill="#a096dd"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_7" data-name="Group 7">
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M506.915,324.143l-8.971-8.972-35.875,35.875,8.971,8.972Z"
                  fill="#a096dd"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_8" data-name="Group 8">
                <rect
                  id="Rectangle_3"
                  data-name="Rectangle 3"
                  width="50.66"
                  height="12.676"
                  transform="translate(478.374 377.555)"
                  fill="#a096dd"
                />
              </g>
              <g id="Group_9" data-name="Group 9">
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M455.59,442.37l11.555,27.853,11.7-4.853L467.3,437.538Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_10" data-name="Group 10">
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M397.522,302.4l11.556,27.853,11.7-4.832-11.555-27.853Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_11" data-name="Group 11">
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M409.142,437.564,397.618,465.43l11.7,4.84,11.515-27.845Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_12" data-name="Group 12">
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M467.053,297.533,455.529,325.4l11.694,4.86,11.524-27.866Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_13" data-name="Group 13">
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M379.713,401.292,351.86,412.847l4.853,11.7L384.545,413Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_14" data-name="Group 14">
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M519.679,343.224,491.826,354.78l4.832,11.7,27.853-11.555Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_15" data-name="Group 15">
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M384.533,354.842l-27.866-11.524-4.839,11.7,27.845,11.515Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_16" data-name="Group 16">
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M524.564,412.753,496.7,401.229l-4.86,11.694,27.86,11.524Z"
                  fill="#631a86"
                  fill-rule="evenodd"
                />
              </g>
              <g id="Group_17" data-name="Group 17">
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M616.974,370.364c12.179,0,20.878-4.712,25.591-13.992l-5.655-1.812c-3.7,7.467-10.512,11.019-19.936,11.019-13.992,0-21.821-7.9-21.821-21.386,0-13.411,7.829-21.023,22.256-21.023,9.787,0,16.094,3.407,19.5,10.8l5.582-1.812c-4.35-9.207-12.614-13.774-25.083-13.774-17.834,0-28.056,9.642-28.056,25.881C589.353,360.577,599.5,370.364,616.974,370.364Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_18" data-name="Group 18">
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M680.478,370.364c17.254,0,27.911-10.222,27.911-25.953,0-15.8-10.657-26.026-27.911-26.026-17.109,0-27.838,10.222-27.838,26.026C652.64,360.142,663.369,370.364,680.478,370.364Zm0-4.93c-13.7,0-21.893-8.119-21.893-21.023s8.119-21.1,21.893-21.1,21.966,8.191,21.966,21.1c0,12.831-8.192,21.023-21.966,21.023Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_19" data-name="Group 19">
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M738.548,363.767,720.5,319.11h-6.524l21.313,50.529h6.525l21.241-50.529H756.53Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_20" data-name="Group 20">
                <rect
                  id="Rectangle_4"
                  data-name="Rectangle 4"
                  width="6.017"
                  height="50.529"
                  transform="translate(773.852 319.11)"
                  fill="#21222d"
                />
              </g>
              <g id="Group_21" data-name="Group 21">
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M818.217,369.639c16.964,0,26.316-8.845,26.316-24.866,0-16.311-9.569-25.663-26.171-25.663H794.946v50.529Zm-17.253-45.6h16.673c12.18,0,20.879,6.308,20.879,20.589,0,15.151-10.439,20.081-21.386,20.081H800.964Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_22" data-name="Group 22">
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M628.356,448.175v-10.8h-25.3V397.646H589.933v50.529Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_23" data-name="Group 23">
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M679.027,448.175V438.6H650.391V427.586H677.07v-9.714H650.391V407.215h28.636v-9.569H637.27v50.529Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_24" data-name="Group 24">
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M743.766,448.175l-21.749-50.529H708.171l-21.748,50.529H700.7l3.552-9.135h21.6l3.479,9.135ZM715.13,411.057l7.032,18.342H708.026Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_25" data-name="Group 25">
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M789.437,428.674c6.235-1.813,10.729-6.017,10.729-15.152,0-13.121-9.5-15.876-21.241-15.876H752.03v50.529h13.049V429.906h11.164l10.584,18.269h15.007Zm-11.019-21.459c3.7,0,8.554.435,8.554,6.452,0,6.09-4.784,6.6-8.554,6.6H765.079V407.215Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_26" data-name="Group 26">
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M848.592,428.819l-25.155-31.173H811.33v50.529h13.122V416.857l25.155,31.318h12.18V397.646h-13.2Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_27" data-name="Group 27">
                <rect
                  id="Rectangle_5"
                  data-name="Rectangle 5"
                  width="13.267"
                  height="50.529"
                  transform="translate(873.821 397.646)"
                  fill="#21222d"
                />
              </g>
              <g id="Group_28" data-name="Group 28">
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M936.458,428.819,911.3,397.646H899.2v50.529h13.122V416.857l25.156,31.318h12.179V397.646h-13.2Z"
                  fill="#21222d"
                />
              </g>
              <g id="Group_29" data-name="Group 29">
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M1004.96,429.761c-.29,5-3.044,9.714-14.136,9.714-12.324,0-18.124-6.524-18.124-16.456,0-10.222,5.437-16.674,17.544-16.674,8.409,0,12.9,2.9,15.659,8.917l12.4-3.842c-4.784-9.932-15.006-15.006-27.91-15.006-18.776,0-30.81,10.511-30.81,26.968,0,15.078,11.091,26.025,30.3,26.025,7.685,0,14.137-1.667,18.124-5.944l.653,4.712h9.641V420.409H992.494v9.352Z"
                  fill="#21222d"
                />
              </g>
            </g>
            <g
              id="Group_2-2"
              data-name="Group 2"
              transform="translate(-29.867 -282.522)"
            >
              <g id="Rectangle_1-2" data-name="Rectangle 1">
                <rect
                  id="Rectangle_1-3"
                  data-name="Rectangle 1"
                  width="49"
                  height="23"
                  rx="4"
                  transform="translate(651.867 350.522)"
                  fill="#a096dd"
                />
              </g>
              <g id="BETA" style="isolation: isolate">
                <g
                  id="Group_1-2"
                  data-name="Group 1"
                  style="isolation: isolate"
                >
                  <path
                    id="Path_1-2"
                    data-name="Path 1"
                    d="M661.749,366.521v-8.934h2.884a4.007,4.007,0,0,1,2.287.533,1.839,1.839,0,0,1,.762,1.613,2.741,2.741,0,0,1-.078.654,2.085,2.085,0,0,1-.243.584,1.935,1.935,0,0,1-.41.471,1.827,1.827,0,0,1-.581.322,2.069,2.069,0,0,1,.639.215,1.889,1.889,0,0,1,.9,1.022,2.162,2.162,0,0,1,.134.779,2.4,2.4,0,0,1-.916,2.054,4.349,4.349,0,0,1-2.646.687Zm4.183-6.494a1.216,1.216,0,0,0-.082-.458.834.834,0,0,0-.263-.348,1.308,1.308,0,0,0-.465-.223,2.622,2.622,0,0,0-.687-.078h-1.018v2.365h1.018a2.123,2.123,0,0,0,.694-.1,1.25,1.25,0,0,0,.465-.274,1.019,1.019,0,0,0,.26-.4A1.482,1.482,0,0,0,665.932,360.027Zm-1.34,5.12a2.23,2.23,0,0,0,.708-.1,1.48,1.48,0,0,0,.509-.283,1.2,1.2,0,0,0,.311-.428,1.312,1.312,0,0,0,.106-.526,1.075,1.075,0,0,0-.434-.91,2.207,2.207,0,0,0-1.33-.328h-1.045v2.577Z"
                    fill="#631a86"
                  />
                  <path
                    id="Path_2-2"
                    data-name="Path 2"
                    d="M669.822,366.521v-8.934h5.38v1.422h-3.671v2.221h3.5v1.388h-3.5V365.1H675.2v1.421Z"
                    fill="#631a86"
                  />
                  <path
                    id="Path_3-2"
                    data-name="Path 3"
                    d="M680.971,358.988v7.533h-1.722v-7.533H676.8v-1.4h6.617v1.4Z"
                    fill="#631a86"
                  />
                  <path
                    id="Path_4-2"
                    data-name="Path 4"
                    d="M689.77,366.521l-.493-1.763H686.16l-.506,1.763h-1.668l2.646-8.934h2.351l2.646,8.934Zm-2.031-7.293-1.176,4.156H688.9Z"
                    fill="#631a86"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="search">
          <input
            type="search"
            class="searchTerm"
            name="term"
            id="term"
            formControlName="term"
            maxlength="512"
            autocomplete="off"
            title="Search"
            aria-label="Search"
            dir="ltr"
            spellcheck="false"
            autofocus="autofocus"
          />
          <button type="submit" value="Search" class="searchButton">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  </nav>
  <div class="wrapAfterSearch">
    <div *ngFor="let article of articlesList.articles" class="articleList">
      <!-- [routerLink]="['article', article.pmid]" -->
      <div
        style="cursor: pointer"
        (click)="article.showDetail = !article.showDetail"
      >
        <h3 style="font-weight: bold; color: #631a86">
          <i
            title="Other"
            class="fas fa-file"
            *ngIf="!(article.isMa || article.isRct || article.isSr)"
          ></i
          ><i title="RCT" class="fas fa-file-alt" *ngIf="article.isRct"></i>
          <i
            title="Systematic Review"
            class="fas fa-layer-group"
            *ngIf="article.isSr"
          ></i>
          <span
            title="Meta-analysis"
            class="svg-inline--fa fa-w-12"
            *ngIf="article.isMa"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 512 512"
            >
              <g
                id="Group_3"
                data-name="Group 3"
                transform="translate(-313.173 -150.145)"
              >
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M442.173,246.145v32h128v-32h199v32h24v-128h-24v32h-199v-32h-128v32h-104v-32h-25v128h25v-32Z"
                  fill="#631a86"
                />
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M689.173,539.164H611.192l-58.019-58.019-58.019,58.019H417.173v-32h-24v128h24v-32h77l59,59,59-59h77v32h24v-128h-24Z"
                  fill="#631a86"
                />
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M801.173,316.145v32h-109v-32h-128v32h-138v-38h-24v128h24v-26h138v32h128v-32h109v32h24v-128Z"
                  fill="#631a86"
                />
              </g>
            </svg>
          </span>
          {{ article.title }}
        </h3>
        <div *ngIf="article && article.classification">
          <div
            class="clinicalAreaChip"
            *ngFor="
              let clinical of classificationSortBy(article.classification)
            "
          >
            {{ clinical.category }}
          </div>
        </div>
        <div style="clear: both"></div>
        <p
          *ngIf="article.abstract"
          [ngClass]="{
            abstractMore: article.showDetail,
            abstractLess: !article.showDetail
          }"
        >
          {{ article.abstract }}
        </p>
        <p *ngIf="!article.abstract" class="abstractMore">
          <i>No abstract available</i>
        </p>
        <p style="margin-bottom: 14px">
          <small style="color: #a096dd; font-size: x-small"
            ><strong>
              <span *ngFor="let author of article.authors; let isLast = last"
                >{{ author }}{{ isLast ? "" : ", " }}
              </span>
              | <span>{{ article.journal }}</span> |
              <span> {{ article.publicationDate }}</span> |
              <span>PMID: {{ article.pmid }}</span>
            </strong></small
          >
        </p>

        <div
          *ngIf="article.isMa || article.isRct || article.isSr"
          style="
            display: flex;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: start;
          "
        >
          <i class="fas fa-tachometer-alt primary"></i>
          &nbsp;&nbsp;&nbsp;
          <app-gradient-progressbar
            [type]="1"
            [progressBarWidth]="10"
            [barCount]="10"
            [value]="calcScore(article.score)"
            [quartileValueLow]="0"
            [quartileValueHigh]="0"
            [showToolTip]="false"
          ></app-gradient-progressbar>
        </div>
      </div>

      <ng-container *ngIf="article.showDetail">
        <ng-container *ngIf="article.isMa">
          <h4>PRISMA evaluation parameters</h4>
          <h5 class="primary">POSSIBLY COMPLIES</h5>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question0?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >This systematic review claims to comply with PRISMA and/or MOOSE
              guidelines</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question1?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >A review protocol exists</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question3?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >No language-based exclusion criteria were defined</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question11?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >Authors were contacted to provide missing information</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question14?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >A risk of bias assessment of individual studies was
              conducted</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question16?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >For procedure involving more than one reviewer, methods for
              solving disagreements are provided</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question172?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >Is there a description of the adopted meta-analysis model and
              methods?</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question174?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >Is there indication that the authors assessed the possibility of
              publication or selective reporting bias (e.g., by means of a
              funnel plot)?</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question175?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >Is there indication that the authors had intention to explore
              sources of heterogeneity (even if that had not been done on
              account of low heterogeneity or insufficient number of
              studies)?</span
            >
          </p>
          <h5 class="secondary">POSSIBLY DOESN'T COMPLY</h5>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question0?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >This systematic review claims to comply with PRISMA and/or MOOSE
              guidelines</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question1?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >A review protocol exists</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question3?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >No language-based exclusion criteria were defined</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question11?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >Authors were contacted to provide missing information</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question14?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >A risk of bias assessment of individual studies was
              conducted</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question16?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >For procedure involving more than one reviewer, methods for
              solving disagreements are provided</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question172?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >Is there a description of the adopted meta-analysis model and
              methods?</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question174?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >Is there indication that the authors assessed the possibility of
              publication or selective reporting bias (e.g., by means of a
              funnel plot)?</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question175?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >Is there indication that the authors had intention to explore
              sources of heterogeneity (even if that had not been done on
              account of low heterogeneity or insufficient number of
              studies)?</span
            >
          </p>
        </ng-container>
        <ng-container *ngIf="!article.isMa && article.isSr">
          <h4>PRISMA evaluation parameters</h4>
          <h5 class="primary">POSSIBLY COMPLIES</h5>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question0?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >This systematic review claims to comply with PRISMA and/or MOOSE
              guidelines</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question1?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >A review protocol exists</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question3?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >No language-based exclusion criteria were defined</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question11?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >Authors were contacted to provide missing information</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question14?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >A risk of bias assessment of individual studies was
              conducted</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question16?.answer === 'Yes'"
          >
            <i class="fas fa-check primary"></i>&nbsp;<span
              >For procedure involving more than one reviewer, methods for
              solving disagreements are provided</span
            >
          </p>
          <h5 class="secondary">POSSIBLY DOESN'T COMPLY</h5>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question0?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >This systematic review claims to comply with PRISMA and/or MOOSE
              guidelines</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question1?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >A review protocol exists</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question3?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >No language-based exclusion criteria were defined</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question11?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >Authors were contacted to provide missing information</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question14?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >A risk of bias assessment of individual studies was
              conducted</span
            >
          </p>
          <p
            class="prismaEvalParam"
            *ngIf="article.p5?.predictions?.question16?.answer === 'No'"
          >
            <i class="fas fa-times secondary"></i>&nbsp;<span
              >For procedure involving more than one reviewer, methods for
              solving disagreements are provided</span
            >
          </p>
        </ng-container>
        <ng-container *ngIf="article.isRct">
          <h4>Cochrane Risk of Bias</h4>
          <h5
            class="primary"
            *ngIf="article.robotReviewer && hasAnyLow(article.robotReviewer)"
          >
            Low
          </h5>
          <ng-container *ngFor="let annotation of article.robotReviewer">
            <p class="prismaEvalParam" *ngIf="annotation.judgement === 'low'">
              <i class="fas fa-check primary"></i>
              &nbsp;<span>{{ annotation.domain }}</span>
            </p>
          </ng-container>
          <h5
            class="primary"
            *ngIf="
              article.robotReviewer && hasAnyUnclear(article.robotReviewer)
            "
          >
            Unclear
          </h5>
          <ng-container *ngFor="let annotation of article.robotReviewer">
            <p class="prismaEvalParam" *ngIf="annotation.judgement !== 'low'">
              <i class="fas fa-times secondary"></i>&nbsp;<span>{{
                annotation.domain
              }}</span>
            </p>
          </ng-container>

          <!-- <p>Allocation concealment</p>
            <p>Blinding of participants and personnel</p>
            <p>Blinding of outcome assessment</p>
            <p>judgement :"low"/"high/unclear"</p> -->
        </ng-container>
      </ng-container>
      <p
        style="text-align: right"
        *ngIf="article.isMa || article.isRct || article.isSr"
      >
        <small>Give us your feedback:</small>
        &nbsp;&nbsp;&nbsp;
        <span (click)="article.feedback = 1" *ngIf="article.feedback !== 1">
          <i class="far fa-thumbs-up primary" style="cursor: pointer"></i>
        </span>
        <span (click)="article.feedback = 0" *ngIf="article.feedback === 1">
          <i class="fas fa-thumbs-up primary" style="cursor: pointer"></i>
        </span>
        <!-- <i class="fas fa-thumbs-up primary"></i> -->
        &nbsp;&nbsp;&nbsp;
        <span (click)="article.feedback = -1" *ngIf="article.feedback !== -1">
          <i class="far fa-thumbs-down primary" style="cursor: pointer"></i>
        </span>
        <span (click)="article.feedback = 0" *ngIf="article.feedback === -1">
          <i class="fas fa-thumbs-down primary" style="cursor: pointer"></i>
        </span>
        <!-- <i class="fas fa-thumbs-down primary"></i> -->
      </p>
      <!-- <p>Article Type: {{ article.articleType }}</p>
        <p>Article Score: {{ article.score }}</p> -->
      <!-- <p>Type: {{article.articleType}} Score: {{article.score}}</p> -->
      <!-- <p>Subjects: <span *ngFor="let item of article.subjects; last as isLast">{{isLast ? item : item+', '}}</span></p> -->
    </div>

    <small
      ><i>{{ result }}</i></small
    >
  </div>
</ng-container>
