import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {
  Classification,
  Client,
  RobotReviewer,
  Score,
  SearchPage,
  SearchQuery,
  Sync4DataResult,
} from '../services/api';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit {
  // term: string = '';
  formSearch!: FormGroup;
  articlesList = new SearchPage();
  pageSize = 1;
  pageIndex = 1;
  searching = false;
  result = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private searchClient: Client
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      // this.term = queryParams.term ?? ''; // queryParams['term'] === 'aaa' ? true : false;
      // console.log(this.term);
      this.createForm(queryParams.term ?? '');
      this.onSubmit();
    });
  }

  createForm(term: string): void {
    this.formSearch = this.formBuilder.group({
      term,
    });
    this.articlesList = new SearchPage();
  }

  // change the query string when the user type
  search(term: string): void {
    this.router.navigate([], { queryParams: { term } });
  }

  onSubmit(): void {
    if (this.formSearch.value.term) {
      this.searching = true;
      this.router.navigate(['/'], {
        queryParams: { term: this.formSearch.value.term },
      });
      const startTimestamp: number = new Date().getTime();
      this.searchClient
        .search(
          new SearchQuery({
            term: this.formSearch.value.term,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          })
        )
        .subscribe((result) => {
          this.articlesList = result;
          this.searching = false;

          const endTimestamp: number = new Date().getTime();
          const responseTimes = endTimestamp - startTimestamp;

          if (this.articlesList.total && this.articlesList.total > 0) {
            this.result =
              'About ' +
              this.articlesList.total +
              ' results (' +
              responseTimes / 1000.0 +
              ' seconds) ';
          } else {
            this.result = '';
          }
        });
    } else {
      this.articlesList = new SearchPage();
      this.router.navigate(['/']);
    }
  }

  classificationSortBy(array: Classification[]): Classification[] {
    return array.sort((a, b) =>
      (a.category ?? '').localeCompare(b.category ?? '')
    );
  }

  calcScore(score: Score | undefined): number {
    if (score === undefined) {
      return 0;
    } else {
      return (score.scoreValue ?? 0) * 100;
    }
  }

  setFeedback(article: Sync4DataResult, value: number): void {
    article.feedback = value;
  }

  hasAnyLow(robotReviewerData: RobotReviewer[]): boolean {
    let low = false;
    robotReviewerData.forEach((element) => {
      if (element.judgement === 'low') {
        low = true;
      }
    });
    return low;
  }

  hasAnyUnclear(robotReviewerData: RobotReviewer[]): boolean {
    let unclear = false;
    robotReviewerData.forEach((element) => {
      if (element.judgement !== 'low') {
        unclear = true;
      }
    });
    return unclear;
  }
}
