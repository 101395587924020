<div
  [routerLink]="['/']"
  style="text-align: center; margin: 32px; cursor: pointer"
></div>
<div class="wrap" *ngIf="article && article.pmid">
  <p>XXXXXXXXXXXXXXXXXXXX</p>
  <p>{{ article.journal }}. {{ article.publicationDate }}</p>
  <h1>{{ article.title }}</h1>
  <p>
    <span *ngFor="let item of article.authors; last as isLast">{{
      isLast ? item : item + ", "
    }}</span>
  </p>
  <p>
    PMID: {{ article.pmid }} PMCID: {{ article.pmc }} DOI: {{ article.doi }}
  </p>
  <p>Type: {{ article.articleType }}</p>
  <p>Score: {{ article.score }}</p>
  <h2>Abstract</h2>
  <p>{{ article.abstract }}</p>
</div>
